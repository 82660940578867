const fusionObjectives = [
  {
    name: 'Get started',
    description: 'Start your journey to a personalized search experience.',
    tag: 'get-started',
    icon: '/assets/icons/category-getStarted.svg',
  },
  {
    name: 'Index your content',
    description: 'Get your content into Fusion by indexing it in a collection. Find a connector for your data, configure your datasource, preview your indexed data, and run a datasource job to make your content available for querying.',
    tag: 'index',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Query your content',
    description: 'Query Fusion to access your indexed content. More advanced query methods are available when you personalize your app.',
    tag: 'query',
    icon: '/assets/icons/category-queryContent.svg'
  },
  {
    name: 'Build Fusion-Powered apps',
    description: 'Build a front end that delivers Fusion-powered content to your customers.',
    tag: 'build-apps',
    icon: '/assets/icons/category-buildFusionApps.svg'
  },
  {
    name: 'Personalize your apps',
    description: 'Use Fusion’s AI features to give each of your unique customers a personalized experience with your content.',
    tag: 'personalize',
    icon: '/assets/icons/category-personalizeApps.svg'
  },
  {
    name: 'Analyze data',
    description: 'Analyze the activity on your Fusion platform to find new ways to innovate and optimize.',
    tag: 'analyze-data',
    icon: '/assets/icons/category-analyzeData.svg'
  },
  {
    name: 'Deploy and upgrade',
    description: 'Deploy your scalable Fusion clusters and keep them up to date with the latest releases.',
    tag: 'deploy',
    icon: '/assets/icons/category-deployUpgrade.svg'
  },
  {
    name: 'Manage your system',
    description: 'How to administrate and monitor your Fusion platform.',
    tag: 'manage',
    icon: '/assets/icons/category-manageSystem.svg'
  },
  {
    name: 'Secure',
    description: 'Lock down your data and secure your Fusion system.',
    tag: 'secure',
    icon: '/assets/icons/category-secureSystem.svg'
  },
  {
    name: 'Troubleshoot',
    description: 'Solve problems and find additional resources here.',
    tag: 'troubleshoot',
    icon: '/assets/icons/category-troubleShoot.svg'
  }
]

const springboardObjectives = [
  {
    name: 'Manage Springboard',
    description: 'Manage your Springboard plan and features.',
    tag: 'manage-sb',
    icon: '/assets/icons/category-getStarted.svg',
  },
  {
    name: 'Manage applications',
    description: 'Create, manage, edit, and delete applications.',
    tag: 'manage-apps',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Manage users',
    description: 'Create, manage, edit, and delete users.',
    tag: 'manage-users',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Manage data sources',
    description: 'Create, manage, edit, and delete data sources.',
    tag: 'manage-data-sources',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Curate results',
    description: 'Use rules to ensure your application is returning the ideal results.',
    tag: 'curate-results',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Integrate',
    description: 'Integrate your application with your search environment.',
    tag: 'integrate',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Analyze',
    description: 'Analyze metrics related to your application to make informed decisions.',
    tag: 'analyze',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Secure',
    description: 'Keep your data safe.',
    tag: 'secure',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Use APIs',
    description: 'Access and utilize Springboard APIs.',
    tag: 'use-apis',
    icon: '/assets/icons/category-indexContent.svg'
  },
  {
    name: 'Troubleshoot',
    description: 'Troubleshoot issues and errors that occur.',
    tag: 'troubleshoot',
    icon: '/assets/icons/category-indexContent.svg'
  },
]

const objectivePretty = {
  'get-started': 'Get started',
  'index': 'Index your content',
  'query': 'Query your content',
  'build-apps' : 'Build Fusion-Powered apps',
  'personalize': 'Personalize your apps',
  'analyze-data': 'Analyze data',
  'deploy': 'Deploy and upgrade',
  'manage': 'Manage your system',
  'secure': 'Secure',
  'troubleshoot': 'Troubleshoot',
  'analyze': 'Analyze',
  'use-apis': 'Use APIs',
  'integrate': 'Integrate',
  'curate-results': 'Curate Results',
  'manage-sb': 'Manage Springboard',
  'manage-apps': 'Manage applications',
  'manage-users': 'Manage Users',
  'manage-data-sources': 'Manage data sources'
}

module.exports = {
  fusionObjectives,
  springboardObjectives,
  objectivePretty
}
